import React from "react";


export const Stakeholders = props => {
    //TODO: Create stakeholders page

    return (
        <div>
            <h1>Stakeholders</h1>
        </div>
    );
}