import React from "react";


export const Listing = props => {
	//TODO: Create listing page

	return (
		<div>
			<h1>Job Listing</h1>
		</div>
	);
}