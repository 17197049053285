import React from "react";


export const Admin = props => {
    //TODO: Create admin page

    return (
        <div>
            <h1>Administrator</h1>
        </div>
    );
}