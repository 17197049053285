export default {
  LOGIN_REQUEST: "AUTH_LOGIN_REQUEST",
  LOGIN_SUCCESS: "AUTH_LOGIN_SUCCESS",
  LOGIN_ERROR: "AUTH_LOGIN_ERROR",
  LOGOUT_REQUEST: "AUTH_LOGOUT_REQUEST",
  LOGOUT_SUCCESS: "AUTH_LOGOUT_SUCCESS",
  LOGOUT_ERROR: "AUTH_LOGOUT_ERROR",
  REGISTER_REQUEST: "AUTH_REGISTER_REQUEST",
  REGISTER_SUCCESS: "AUTH_REGISTER_SUCCESS",
  REGISTER_ERROR: "AUTH_REGISTER_ERROR",
  UPDATE_PROFILE: "AUTH_UPDATE_PROFILE"
}